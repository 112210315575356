import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="July 2022" subnav="release-notes">
      <div id="July2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          July was a sunny month full of beach vacations and changes for the
          squad maintaining Uniform. The updates this month are light from a
          product perspective, but will have a big impact on the quality of
          Uniform code and contributions!
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.20.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                <Link href="https://stylelint.io/">Stylelint</Link> to the web
                repo for better adherence to accessibility and Uniform styling
                rules.
              </Added>
              <Improved>
                Unit tests by switching to{' '}
                <Link href="https://testing-library.com/docs/react-testing-library/intro/">
                  React Testing Library
                </Link>{' '}
                and replacing all current tests.
              </Improved>
              <Fixed>
                Issue due to unnecessary TypeScript prop definition for{' '}
                <code>onChange</code> prop in{' '}
                <Link href="/components/forms/select/code?web#Creatable%20Select">
                  Creatable Selects
                </Link>
                .
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.0.0"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Improved>
                Our React Native library has officially moved to the new{' '}
                <Link href="https://github.com/hudl/rn-uniform">
                  <code>rn-uniform</code>
                </Link>{' '}
                repo.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
